<template>
    <div id="TOCNavigation" class="overlay toc-navigation">
        <a href="javascript:void(0)" class="closebtn" @click="this.$functions.TOCNavigationClose()"><i class="fa fa-times"></i></a>
        <div class="overlay-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 col-xs-b30 col-sm-0">
                        <div class="sub-menu">
                            <ul>
                                <li><a href="#">HOME</a></li>
                                <li><a href="#">ABOUT</a></li>
                                <li><a href="#">PORTOFOLIO</a></li>
                                <li><a href="#">SERVICE</a></li>
                                <li><a href="#">BLOG</a></li>
                                <li><a href="#">PAGES</a></li>
                                <li><a href="#">ELEMENTS</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-md-offset-1 col-xs-b30 col-sm-0">
                        <div class="information">
                            <h5>INFORMATION</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                            <div class="empty-space col-xs-b10"></div>
                        </div>
                        <div class="empty-space col-xs-b10"></div>
                        <div class="contact">
                            <h5>CONTACT INFO</h5>
                            <p>Email: <b>administrator@webmagz.org</b></p>
                            <p>Phone: <b>+62 8xx - xxxx- xxxx</b></p>
                            <div class="empty-space col-xs-b10"></div>
                        </div>
                        <div class="empty-space col-xs-b10"></div>
                        <div class="follow">
                            <h5>FOLLOW ME</h5>
                            <div class="empty-space col-xs-b10"></div>
                            <a class="item" href="#" target="_blank"><i class="fa fa-facebook"></i></a>
                            <a class="item" href="#" target="_blank"><i class="fa fa-twitter"></i></a>
                            <a class="item" href="#" target="_blank"><i class="fa fa-instagram"></i></a>
                            <a class="item" href="#" target="_blank"><i class="fa fa-youtube-play"></i></a>
                            <a class="item" href="#" target="_blank"><i class="fa fa-pinterest"></i></a>
                            <a class="item" href="#" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-md-offset-1">
                        <div class="twitter">
                            <div class="row col-xs-b15">
                                <div class="col-xs-6">
                                    <div class="date"><i>20 min ago</i></div>  
                                </div>
                                <div class="col-xs-6 text-right">
                                    <i class="fa fa-twitter"></i>
                                </div>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <div class="empty-space col-xs-b10"></div>
                            <a href="#">@modestodesign</a>
                            <div class="empty-space col-xs-b10"></div>
                            <a href="#"><i>https://www.youtube.com/</i></a>
                        </div>
                        <div class="empty-space col-xs-b30"></div>
                        <div class="thumbnails-wrapper">
                            <div class="row nopadding">
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                    </a>
                                </div>
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                    </a>
                                </div>
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                    </a>
                                </div>
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                    </a>
                                </div>
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-1.jpg" alt="">
                                    </a>
                                </div>
                                <div class="col-xs-4">
                                    <a class="overlay-thumbnail" href="#">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                        <img src="/img/webmagz/thumbnail-2.jpg" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TOCNavigation',
    props: [
      'type' 
    ],
    methods: {
      
    }
}
</script>