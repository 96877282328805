export default {
    FirstLoad () {
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        return 
    },

    TOCNavigationOpen () {
        document.getElementById("TOCNavigation").style.height = "100%";
    },

    TOCNavigationClose () {
        document.getElementById("TOCNavigation").style.height = "0%";
    },

    Loading(timer) {
        setTimeout(() => {
            document.getElementById("content-block").style.opacity = "1";
        }, timer);
    }
}