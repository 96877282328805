<template>
    <nav class="navbar navbar-default navbar-fixed-top style-1" v-bind:class="type">
      <div class="container-fluid fluid-1">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="#">
            <img v-if="type == 'transparent-light'" :src="'/img/webmagz/webmagz-logo-light.png'" alt="">
            <img v-else :src="'/img/webmagz/webmagz-logo-dark.png'" alt="">
          </a>
        </div>
        <div id="navbar" class="navbar-collapse collapse">
          <ul class="nav navbar-nav navbar-right">
            <li @click="RedirectView('/')" v-bind:class="[this.$route.path == '/' ? 'active' : '']"><a class="nav-items">HOME</a></li>
            <li @click="RedirectView('about')" v-bind:class="[this.$route.path == '/about' ? 'active' : '']"><a class="nav-items">ABOUT</a></li>
            <li @click="RedirectView('advertisement')" v-bind:class="[this.$route.path == '/advertisement' ? 'active' : '']"><a class="nav-items">ADVERTISEMENT</a></li>
            <li @click="RedirectView('donation')" v-bind:class="[this.$route.path == '/donation' ? 'active' : '']"><a class="nav-items">DONATION</a></li>
            <li @click="RedirectView('faq')" v-bind:class="[this.$route.path == '/faq' ? 'active' : '']"><a class="nav-items">FAQ</a></li>
            <li @click="RedirectView('report')" v-bind:class="[this.$route.path == '/report' ? 'active' : '']"><a class="nav-items">REPORT</a></li>
            <li @click="RedirectView('getintouch')" v-bind:class="[this.$route.path == '/getintouch' ? 'active' : '']"><a class="nav-items">GET IN TOUCH</a></li>
            <li @click="RedirectView('login')" class="login"><a class="nav-items">LOGIN</a></li>
            <li class="bars"><a href="javascript:void(0)" @click="this.$functions.TOCNavigationOpen()"><i class="fa fa-bars"></i></a></li>
          </ul>
        </div><!--/.nav-collapse -->
      </div>
    </nav>
</template>

<script>
export default {
    name: 'TopNavigation',
    props: [
      'type' 
    ],
    methods: {
      RedirectView(path) {
        this.$router
          .push({ path: path })
          .then(() => { this.$router.go() })
      }
    }
}
</script>