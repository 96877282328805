<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-6">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/arrow-polygon.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <h2 class="m-t-0 m-b-0">GET IN TOUCH</h2>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <div class="content-body">
                                <div class="form-getintouch">
                                    <form class="form-1" action="">
                                        <div class="row">

                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                <input type="text" class="form-control" id="name" placeholder="Nama Anda">
                                                <div class="empty-space col-xs-b10"></div>
                                              </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-6">
                                                <input type="email" class="form-control" id="email" placeholder="Email">
                                                <div class="empty-space col-xs-b10"></div>
                                              </div>
                                              <div class="col-sm-6">
                                                <input type="text" class="form-control" id="phone" placeholder="Nomor HP/Telepon">
                                                <div class="empty-space col-xs-b10"></div>
                                              </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                  <select class="form-control" id="sel1">
                                                    <option>Subjek</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                  </select>
                                                  <div class="empty-space col-xs-b10"></div>
                                              </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                <textarea class="form-control" rows="5" id="comment" placeholder="Tulis Pesan Disini"></textarea>
                                                <div class="empty-space col-xs-b10"></div>
                                              </div>
                                            </div>

                                            <div class="form-check">
                                                <div class="col-sm-12">
                                                    <input type="checkbox" class="form-check-input" id="checksender">&nbsp;
                                                    <label class="form-check-label" for="checksender"> Centang apabila anda bukan robot</label>
                                                    <div class="empty-space col-xs-b10"></div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-4">
                                                <button type="submit" class="btn btn-default"><b>KIRIM</b></button>
                                              </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'GetInTouchView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>