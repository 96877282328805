import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import AdvertisementView from '../views/AdvertisementView.vue'
import DonationView from '../views/DonationView.vue'
import FAQView from '../views/FAQView.vue'
import ReportView from '../views/ReportView.vue'
import GetInTouchView from '../views/GetInTouchView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import TermsConditionsView from '../views/TermsConditionsView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import SinglePageContentView from '../views/SinglePageContentView.vue'
import MultiPageContentView from '../views/MultiPageContentView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/advertisement',
    name: 'advertisement',
    component: AdvertisementView
  },
  {
    path: '/donation',
    name: 'donation',
    component: DonationView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQView
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView
  },
  {
    path: '/getintouch',
    name: 'getintouch',
    component: GetInTouchView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/termsconditions',
    name: 'termsconditions',
    component: TermsConditionsView
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: PrivacyPolicyView
  },
  {
    path: '/content/:id',
    name: 'content',
    component: SinglePageContentView
  },
  {
    path: '/contents/:id',
    name: 'contents',
    component: MultiPageContentView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
