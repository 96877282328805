<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-5">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/webmagz-faq-page-illustration.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <h2 class="m-t-0 m-b-0">WebMagz <span class="color-default">FAQ</span></h2>
                                <h3 class="m-t-0">Mungkin jawabanmu ada disini</h3>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-header-sub" style="display: flex; justify-content: space-between;">
                                <div class="author">
                                    <h6>by <span class="color-default">Admin</span> on <span class="color-default">Nov,25 2022</span></h6>
                                </div>
                                <div class="viewer">
                                    <h6><span class="color-default">112</span> Views</h6>
                                </div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-body">
                                <div class="empty-space col-xs-b20"></div>
                                <div class="panel-group panel-faq" id="accordion">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                                            <b>Apakah ada batasan umur untuk mengakses WebMagz?</b>
                                            </a>
                                        </h4>
                                        </div>
                                        <div id="collapseOne" class="panel-collapse collapse in">
                                        <div class="panel-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                            <b>Kenapa WebMagz tidak terbit tiap hari?</b>
                                            </a>
                                        </h4>
                                        </div>
                                        <div id="collapseTwo" class="panel-collapse collapse">
                                        <div class="panel-body">
                                            Karena WebMagz ingin kamu belajar untuk rindu. Belajar untuk bersabar dan tidak terburu-buru. Disini kami membantumu untuk menjadi probadi yang bikan hanya cerdas secara pengetahuan, namun memiliki kecerdasan emosional yang akan membantumu menjadi pribadi yang positif
                                        </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                            <b>Bagaimana cara agar cerpenku dimuat di WebMagz?</b>
                                            </a>
                                        </h4>
                                        </div>
                                        <div id="collapseThree" class="panel-collapse collapse">
                                        <div class="panel-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </div>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                        <h4 class="panel-title">
                                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                                            <b>Apakah kedepannya akan ada biaya untuk mengakses WebMagz?</b>
                                            </a>
                                        </h4>
                                        </div>
                                        <div id="collapseFour" class="panel-collapse collapse">
                                        <div class="panel-body">
                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'FAQView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>

<style>
.panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content:"\e252";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: #000000;         /* adjust as needed */
}
.panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content:"\e250";    /* adjust as needed, taken from bootstrap.css */
}
</style>