<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-6">
                        <div class="content-left">
                            <div class="content-image image-login">
                                <img src="/img/webmagz/female-camping.png" alt="">
                                <div class="empty-space col-xs-b10"></div>
                                <div class="text-center">
                                    <a href="#" class="link-direct" @click="RedirectView('register')"><b>Tidak Punya Akun?</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <div class="empty-space col-xs-b60"></div>
                                <h2 class="m-t-0 m-b-0">SIGN IN!</h2>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <div class="content-body">
                                <div class="form-login">
                                    <form class="form-1" action="">
                                        <div class="row">
                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                <input type="email" class="form-control" id="name" placeholder="Email">
                                                <div class="empty-space col-xs-b20"></div>
                                              </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-12">
                                                <input type="password" class="form-control" id="name" placeholder="Password">
                                                <div class="empty-space col-xs-b20"></div>
                                              </div>
                                            </div>

                                            <div class="form-check">
                                                <div class="col-sm-12">
                                                    <input type="checkbox" class="form-check-input" id="checksender">&nbsp;
                                                    <label class="form-check-label" for="checksender"> Ingatkan Saya</label>
                                                    <div class="empty-space col-xs-b10"></div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                              <div class="col-sm-4">
                                                <button type="submit" class="btn btn-default"><b>LOGIN</b></button>
                                              </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'LoginView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    RedirectView(path) {
        this.$router
        .push({ path: path })
        .then(() => { this.$router.go() })
    }
  }
}
</script>