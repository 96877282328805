<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-5">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/webmagz-donation-page-illustration.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <h2 class="m-t-0 m-b-0">WebMagz <span class="color-default">Donations</span></h2>
                                <h3 class="m-t-0">Karena ide tidak berhenti mengalir</h3>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-header-sub" style="display: flex; justify-content: space-between;">
                                <div class="author">
                                    <h6>by <span class="color-default">Admin</span> on <span class="color-default">Nov,25 2022</span></h6>
                                </div>
                                <div class="viewer">
                                    <h6><span class="color-default">112</span> Views</h6>
                                </div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-body">
                                <div class="empty-space col-xs-b20"></div>
                                    <p>Media digital yang kaya dan beragam memicu imajinasi untuk tidak berhenti bergerak. Banyak hal yang bisa kita lakukan, dan kami percaya diluar sana banyak orang-orang hebat yang memliki irisan visi dengan kami di WebMagz. Oleh karena itu kamu membuka peluan untuk anda agar dapat berdonasi dan bersama-sama mewujudkan visi untuk ikut serta mengambil peran untuk lahirnya generasi hebat yang akan menjadi pewaris bumi.</p>
                                    <p>Silahkan isi form dibawah ini dan kami akan segera menghubungi anda.</p>
                            </div>

                            <div class="content-footer" style="display: flex; justify-content: space-between;">
                                <div class="form-sender">
                                    <form class="form-inline form-1" action="/action_page.php">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="name" placeholder="Nama Anda">
                                        </div>
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="email" placeholder="Email">
                                        </div>
                                        <div class="checkbox">
                                        </div>
                                        <button type="submit" class="btn btn-default"><b>KIRIM</b></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'DonationView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>