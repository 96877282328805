<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-5">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/webmagz-advertisement-page-illustration.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <h2 class="m-t-0 m-b-0">WebMagz <span class="color-default">Advertisement</span></h2>
                                <h3 class="m-t-0">Produk keren untuk generasi cerdas</h3>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-header-sub" style="display: flex; justify-content: space-between;">
                                <div class="author">
                                    <h6>by <span class="color-default">Admin</span> on <span class="color-default">Nov,25 2022</span></h6>
                                </div>
                                <div class="viewer">
                                    <h6><span class="color-default">112</span> Views</h6>
                                </div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-body">
                                <div class="empty-space col-xs-b20"></div>
                                <p>Sesuai dengan tagline "Produk keren untuk generasi cerdas" <b>WebMagz</b> menghimbau para pengiklan dan calon pengiklan untuk mempertimbankan kualitas produk atau jasa yang akan diiklankan. sehingga memiliki dampak positif bagi generasi muda. Kami berhak menolak iklan yang kami anggap dapat berdampak negatif pada para pembaca WebMagz.</p>
                                <p>Untuk mendapatkan informasi terkait advertisement, silahkan isi form dibawah ini dan kami akan segera mengirimkan proposal berikut pricelist yang menarik.</p>
                                <div class="empty-space col-xs-b20"></div>
                            </div>

                            <div class="content-footer" style="display: flex; justify-content: space-between;">
                                <div class="form-sender">
                                    <form class="form-inline form-1" action="/action_page.php">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="name" placeholder="Nama Anda">
                                        </div>
                                        <div class="form-group">
                                            <input type="email" class="form-control" id="email" placeholder="Email">
                                        </div>
                                        <div class="checkbox">
                                        </div>
                                        <button type="submit" class="btn btn-default"><b>KIRIM</b></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'AdvertisementView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>

<style>
h2.title {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: 3.6em;
}

h3.sub-title {
    font-family: 'Gochi Hand', cursive, Roboto;
}

p.content {
    font-family: 'Balsamiq Sans', cursive;
    /* font-size: 1.4em; */
}

@media (max-width: 750px) {
    p.content {
        /* font-size: 1em;  */
    }
}
</style>