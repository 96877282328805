<template>
    <!-- Footer -->
    <footer class="footer-1" v-bind:class="type">
        <div class="container-fluid fluid-1">
            <div class="row">
                <div class="col-md-6 col-xs-text-center col-md-text-left">
                    <div class="copyright">
                        <h6>COPYRIGHT <sup>&copy;</sup>2023 WEBMAGZ | <a href="#" @click="RedirectView('termsconditions')"> TERMS & CONDITIONS </a> | <a href="#" @click="RedirectView('privacypolicy')"> PRIVACY POLICY </a> | SITEMAP</h6>
                    </div>
                </div>
                <div class="col-md-6 col-xs-text-center col-md-text-right">
                    <div class="social-media light">
                        <a class="item" href="#" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a class="item" href="#" target="_blank"><i class="fa fa-twitter"></i></a>
                        <a class="item" href="#" target="_blank"><i class="fa fa-instagram"></i></a>
                        <a class="item" href="#" target="_blank"><i class="fa fa-youtube-play"></i></a>
                        <a class="item" href="#" target="_blank"><i class="fa fa-pinterest"></i></a>
                        <a class="item" href="#" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterMain',
    props: [
        'type' 
    ],
    methods: {
      RedirectView(path) {
        this.$router
          .push({ path: path })
          .then(() => { this.$router.go() })
      }
    }
}
</script>