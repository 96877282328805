<template>
  <div id="content-block" class="background-image" style="background-image: url(img/webmagz/webmagz-cover-1st.jpg);">
    <TopNavigation type="transparent-light"></TopNavigation>
    <div class="page-height">

    </div>
    <FooterMain type="transparent-light"></FooterMain>
  </div>
  
  <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'HomeView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>

<style>

</style>