<template>  
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-5">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/webmagz-about-page-illustration.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-left">
                        <div class="content-right page-content">
                            <div class="content-header">
                                <div class="empty-space col-xs-b20 col-sm-b0"></div>
                                <h4 class="m-t-0 m-b-10">CATEGORY</h4>
                                <h2 class="m-t-0 m-b-0">Content Title</h2>
                                <h3 class="m-t-0 m-b-0">Content Sub Title</h3>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-header-sub" style="display: flex; justify-content: space-between;">
                                <div class="author">
                                    <h6>by <span class="color-default">Admin</span> on <span class="color-default">Nov,25 2022</span></h6>
                                </div>
                                <div class="viewer">
                                    <h6><span class="color-default">112</span> Views</h6>
                                </div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-body">
                                <div class="empty-space col-xs-b20"></div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui oficia deserunt mollit laborum.</p>
                                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque audantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                <div class="empty-space col-xs-b20"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-footer" style="display: flex; justify-content: space-between;">
                                <div class="liked">
                                    <h5><i class="fa fa-heart"></i> 78 <span class="color-default"><b>I LUV IT!</b></span></h5>
                                </div>
                                <div class="share">
                                    <div class="empty-space col-xs-b10"></div>
                                    <div class="dropdown">
                                        <button class="btn btn-default btn-share dropdown-toggle" type="button" data-toggle="dropdown">
                                            <b>BAGIKAN </b>
                                            <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a href="#">Facebook</a></li>
                                            <li><a href="#">Instagram</a></li>
                                            <li><a href="#">Twitter</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'MultiPageContentView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>