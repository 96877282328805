<template>
    <div id="content-block">
        <!-- Navigation -->
        <TopNavigation></TopNavigation>

        <!-- Content Here -->
        <div class="page-height">
            <div class="container-fluid fluid-1">
                <div class="empty-space col-xs-b120"></div>
                <div class="row content content-1">
                    <div class="empty-space col-xs-b20 col-xl-b80"></div>
                    <div class="col-md-5">
                        <div class="content-left">
                            <div class="content-image">
                                <img src="/img/webmagz/webmagz-about-page-illustration.jpg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 text-left">
                        <div class="content-right">
                            <div class="content-header">
                                <div class="empty-space col-xs-b20 col-sm-b0"></div>
                                <h2 class="m-t-0 m-b-0">Tentang <span class="color-default">WebMagz</span></h2>
                                <h3 class="m-t-0">Cerita di balik ini semua</h3>
                                <div class="empty-space col-xs-b10"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-header-sub" style="display: flex; justify-content: space-between;">
                                <div class="author">
                                    <h6>by <span class="color-default">Admin</span> on <span class="color-default">Nov,25 2022</span></h6>
                                </div>
                                <div class="viewer">
                                    <h6><span class="color-default">112</span> Views</h6>
                                </div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-body">
                                <div class="empty-space col-xs-b20"></div>
                                <p><b>WebMagz</b> adalah majalah online berbasis web yang terbit sebulan sekali dan dapat diakses melalui berbagai device. WebMagz berisi kontent-konten positif yang dapat menginspirasi pengungjungnya untuk menjalani kehidupan secara positif melalui informasi-informasi bermanfaat di tengah maraknya perbedaan informasi yang kurang atau bahkan tidak bermanfaat yang di konsumsi oleh masyarakat, terutama generasi muda yang akan menjadi pewaris peradaban di masa yang akan datang. Selain memiliki format sebagai sebuah majalah online, WebMagz diharapkan menjadi sesuatu ruang privat bagi usernya untuk menyimpan berbagai informasi subjektif yang hanya dapat dinikmati oleh user itu sendiri dan user lain yang dikehendakinya.</p>
                                <div class="empty-space col-xs-b20"></div>
                            </div>
                            <hr style="margin: 0;">
                            <div class="content-footer" style="display: flex; justify-content: space-between;">
                                <div class="liked">
                                    <h5><i class="fa fa-heart"></i> 78 <span class="color-default"><b>I LUV IT!</b></span></h5>
                                </div>
                                <div class="share">
                                    <div class="empty-space col-xs-b10"></div>
                                    <div class="dropdown">
                                        <button class="btn btn-default btn-share dropdown-toggle" type="button" data-toggle="dropdown">
                                            <b>BAGIKAN </b>
                                            <span class="caret"></span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a href="#">Facebook</a></li>
                                            <li><a href="#">Instagram</a></li>
                                            <li><a href="#">Twitter</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FooterMain></FooterMain>

    </div>

    <TOCNavigation></TOCNavigation>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import TOCNavigation from '@/components/TOCNavigation.vue'
import FooterMain from '@/components/FooterMain.vue'

export default {
  name: 'AboutView',
  components: {
    TopNavigation,
    TOCNavigation,
    FooterMain
  },
  data() {
    return {
       
    }
  },
  beforeCreate(){
  },
  created(){
  },
  beforeMount(){
  },
  mounted(){
    this.$functions.FirstLoad()
    this.$functions.Loading(300)
  },
  beforeUpdate(){
  },
  updated(){
  },
  methods: {
    
  }
}
</script>